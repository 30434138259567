<script setup>
const seoTitle = "MIRASOFT - Outsourcing";
const seoDescription = "";

useHead({
  title: seoTitle,
  meta: [
    { name: "description", content: seoDescription },
    { name: "og:title", content: seoTitle },
    { name: "og:description", content: seoDescription },
    { name: "keywords", content: "Outsourcing" },
  ],
});

const brandCooperationList = [
  {
    title: "",
    image: "/images/logo/layer_1.png",
  },
  {
    title: "",
    image: "/images/logo/layer_2.png",
  },
  {
    title: "",
    image: "/images/logo/layer_3.png",
  },
  {
    title: "",
    image: "/images/logo/layer_4.png",
  },
  {
    title: "",
    image: "/images/logo/layer_5.png",
  },
  {
    title: "",
    image: "/images/logo/layer_6.png",
  },
  {
    title: "",
    image: "/images/logo/layer_7.png",
  },
  {
    title: "Join us",
    image: "/images/arrow-down.svg",
  },
];

const listTag = [
  {
    title: "it Integration",
  },
  {
    title: "Web Development",
  },
  {
    icon: "/images/cube.svg",
  },
  {
    title: "IOT",
  },
  {
    title: "Blockchain",
  },
  {
    icon: "/images/abstract.svg",
  },
  {
    title: "AI Solutions",
  },
  {
    title: "App Development",
  },
];

const services = [
  {
    index: 1,
    icon: "/images/customers.svg",
    title: "1000+",
    des: "Customers",
  },
  {
    index: 2,
    icon: "/images/loyal-customers.svg",
    title: ">90%",
    des: "Loyal Customers",
  },
  {
    index: 3,
    icon: "/images/clock.svg",
    title: "16+",
    des: "Years of Experience",
  },
  {
    index: 4,
    icon: "/images/file.svg",
    title: "500+",
    des: "Staff Members",
  },
];

const listCliens = [
  {
    image: "/images/our-cliens/layer_1.svg",
  },
  {
    image: "/images/our-cliens/mobifone-white.svg",
  },
  {
    image: "/images/our-cliens/viettel-white.svg",
  },
  {
    image: "/images/our-cliens/xmlid_1_.svg",
  },
  {
    image: "/images/our-cliens/cj-logo-logo-white.svg",
  },
  {
    image: "/images/our-cliens/path7.svg",
  },
  {
    image: "/images/our-cliens/highlands_coffee_logo-white.svg",
  },
  {
    image: "/images/our-cliens/chicco-logo-white.svg",
  },
  {
    image: "/images/our-cliens/group.svg",
  },
  {
    image: "/images/our-cliens/nike-logo-white.svg",
  },
  {
    image: "/images/our-cliens/zara_logo.svg",
  },
  {
    image: "/images/our-cliens/aldo-logo-white.svg",
  },
];
</script>

<template>
  <div class="home-page px-16 max-xl:px-4 py-10">
    <section class="2xl:container m-auto">
      <div class="flex max-lg:flex-col max-md:items-center gap-4">
        <div class="w-[55%] bg-white rounded-3xl overflow-hidden p-16 relative max-xl:p-4 max-lg:w-full"
          data-aos="fade-right">
          <div class="flex gap-[32px] items-center">
            <nuxt-picture loading="lazy" src="/images/lllustration-shapes.svg" width="60px"
              :imgAttrs="{ alt: 'icon' }" />
            <p class="uppercase text-[18px] font-semibold">technology solutions</p>
          </div>
          <div class="mt-[70px] max-sm:mt-6">
            <nuxt-picture loading="lazy" src="/images/heading.png" class="picture"
              :imgAttrs="{ alt: 'heading', class: 'w-full max-sm:w-[295px]' }" />
          </div>
          <div class="lg:absolute left-16 bottom-16 max-lg:mt-3">
            <nuxt-link to="/contact">
              <button class="primary uppercase max-sm:w-full" aria-label="see-more">Let's start with MIRAsoft</button>
            </nuxt-link>
          </div>
        </div>
        <div class="w-[45%] max-lg:hidden rounded-3xl overflow-hidden" data-aos="fade-left">
          <nuxt-picture loading="lazy" src="/images/img-boxxy2.png" class="picture w-full"
            :imgAttrs="{ class: 'w-full', alt: 'cammera' }" />
        </div>
      </div>
      <div class="flex max-lg:flex-col gap-4 mt-4">
        <div class="w-[55%] max-lg:w-full bg-[#F67D30] p-6 rounded-3xl relative flex justify-center items-center"
          data-aos="fade-up" data-aos-delay="200">
          <div class="flex items-end gap-4 max-sm:flex-col max-sm:items-start">
            <nuxt-picture loading="lazy" src="/images/hero-photo.png"
              :imgAttrs="{ alt: 'hero', class: 'w-full max-md:w-[122px]' }" />
            <h2 class="text-[34px] max-sm:text-[20px] font-bold">Expanded options are available</h2>
          </div>

          <div class="flex items-center gap-4 absolute top-6 right-6">
            <p class="uppercase text-right text-white">
              Explore <br />
              MORE
            </p>
            <div class="w-fit p-3 rounded-[50%] bg-white">
              <nuxt-link to="/services">
                <nuxt-picture loading="lazy" src="/images/logo.svg" width="24px" :imgAttrs="{ alt: 'icon' }" />
              </nuxt-link>
            </div>
          </div>
        </div>
        <div class="w-[45%] max-lg:w-full bg-white p-6 rounded-3xl" data-aos="fade-up" data-aos-delay="300">
          <div class="flex justify-between items-center">
            <div class="flex gap-3 items-center">
              <nuxt-picture loading="lazy" src="/images/90.svg" width="100%" :imgAttrs="{ alt: '90%' }" />
              <p class="font-medium text-[14px] max-sm:text-[12px]">LOYAL <br />CUSTOMERS</p>
            </div>
            <div class="flex max-md:hidden">
              <nuxt-picture loading="lazy" src="/images/img-avatar-women.png" :img-attrs="{ alt: 'avatar women' }" />
              <nuxt-picture loading="lazy" src="/images/img-avatar.png" :img-attrs="{ alt: 'avatar women' }"
                class="-translate-x-5" />
            </div>
          </div>
          <div class="flex flex-wrap gap-2 mt-[54px]">
            <div v-for="tag in listTag">
              <div v-if="tag.title" class="border boroder-[#0B0B0B] px-[19px] py-[10px] rounded-3xl uppercase">
                <p>{{ tag.title }}</p>
              </div>
              <div v-else
                class="w-[42px] h-[42px] rounded-[50%] border boroder-[#0B0B0B] flex justify-center items-center">
                <nuxt-picture loading="lazy" :src="tag.icon" width="100%" :imgAttrs="{ alt: 'icon' }" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="about-us" class="mt-[80px] 2xl:container m-auto">
      <div class="md:bg-[url('/images/about-us-bg-fix.png')] bg-contain bg-no-repeat">
        <div data-aos="fade-up">
          <h2 class="heading-1">About us</h2>
        </div>
        <div class="description max-w-[478px] mx-auto mt-[75px] text-justify" data-aos="fade-up" data-aos-delay="200">
          Established in 2007, Mirasoft has evolved into a leading digital transformation provider, distinguished by our
          expertise in design and development. We envision a digital future for your business, leveraging cutting-edge
          technology, responsive design, and creating unforgettable user experiences.
        </div>

        <div class="text-center mt-[100px]" data-aos="fade-up">
          <h2 class="heading-2">BRands we work with</h2>
        </div>

        <div class="grid grid-cols-4 gap-4 max-lg:grid-cols-2 mt-[100px] max-md:relative">
          <div class="brand-cooperation-item min-h-[275px] max-sm:min-h-[150px]"
            v-for="(brandCooperation, index) in brandCooperationList" :key="index"
            :class="brandCooperation.title !== '' && 'bg-[#F67D30] !border-[#F67D30]'" data-aos="fade-up"
            :data-aos-delay="`${index}00`">
            <nuxt-picture loading="lazy" :src="brandCooperation.image" class="max-md:w-[57px]"
              :imgAttrs="{ alt: brandCooperation.title }" v-if="brandCooperation.title === ''" />
            <div class="text-center" v-else>
              <h2 class="uppercase text-[34px] max-md:text-[20px]">{{ brandCooperation.title }}</h2>
              <div
                class="mx-auto w-[48px] h-[48px] border border-white rounded-[50%] flex justify-center items-center max-md:absolute max-md:border-none max-md:right-1 max-md:bottom-1">
                <nuxt-link to="/services">
                  <nuxt-picture loading="lazy" :src="brandCooperation.image" width="24px" height="24px"
                    :imgAttrs="{ alt: 'arrow-down' }" />
                </nuxt-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="vision" class="mt-[80px] 2xl:container mx-auto" data-aos="fade-up">
      <div class="flex max-xl:flex-col">
        <div class="w-1/2 max-xl:w-full flex">
          <div class="flex gap-8 max-md:gap-3 flex-wrap justify-center">
            <div
              class="w-[calc((100%-64px)/2)] max-md:w-[calc((100%-12px)/2)] flex flex-col justify-center items-center gap-4 max-md:gap-1 border-0 rounded-[20px] p-[40px] max-md:p-3"
              v-for="(service, index) in services" :key="index" :class="`${index % 3 !== 0 ? 'bg-[#F67D30]' : 'bg-white'}
                ${index === 1 || index === 3 ? 'transform translate-y-10' : ''}`">
              <nuxt-picture loading="lazy" :src="service.icon" width="70" height="70" :imgAttrs="{ alt: 'user' }"
                class="max-md:max-w-[41px]" />
              <h4 class="text-[36px] max-md:text-[20px] font-bold text-[#120F1F]"
                :class="index % 3 !== 0 ? 'text-white' : 'text-black'">
                {{ service.title }}
              </h4>
              <p class="description text-center !font-semibold uppercase"
                :class="index % 3 !== 0 ? 'text-white' : '!text-black'">
                {{ service.des }}
              </p>
            </div>
          </div>
        </div>
        <div
          class="w-1/2 max-xl:w-full max-xl:mt-16 flex flex-col gap-[83px] max-md:gap-[30px] justify-center items-center">
          <div class="max-w-[478px]" data-aos="fade-up" data-aos-delay="100">
            <h2 class="heading-2">vision</h2>
            <p class="description mt-7 leading-6">
              Our vision is to be the leading global technology outsourcing partner, empowering businesses worldwide
              with innovative solutions and seamless digital transformations, while contributing to the growth and
              prosperity of Vietnam's technology ecosystem.
            </p>
          </div>
          <div class="max-w-[478px]" data-aos="fade-up" data-aos-delay="200">
            <h2 class="heading-2">mission</h2>
            <p class="description mt-7 leading-6">
              We want to empower global businesses with innovative technology solutions, driving success through
              excellence, collaboration, and a commitment to the global's technology ecosystem.
            </p>
          </div>
        </div>
      </div>
    </section>

    <section id="core-value" class="mt-[100px] 2xl:container m-auto">
      <div class="flex gap-4 max-xl:flex-col">
        <div
          class="w-1/2 max-xl:w-full min-h-[440px] bg-[url('/images/core-value/price-card-main.png')] 
          max-md:bg-[url('/images/core-value/price-card-mobie.png')] bg-no-repeat bg-cover bg-right px-10 py-8 rounded-3xl"
          data-aos="fade-up" data-aos-delay="100">
          <h2 class="heading-3">core values</h2>
          <div class="mt-10 max-w-[386px]">
            <h4 class="uppercase heading-4">Innovation</h4>
            <p class="mt-6 text-[18px] max-md:text-[14px] leading-[25.2px] font-normal text-white">
              Committed to innovation, exploring new ideas and technologies to empower and advance our clients.
              Constantly pushing boundaries, we strive to offer groundbreaking solutions that drive progress and
              success.
            </p>
          </div>
        </div>
        <div class="w-1/2 max-xl:w-full flex items-end gap-4 max-sm:flex-col max-xl:mt-3" data-aos="fade-up">
          <div class="w-1/2 max-sm:w-full md:min-h-[375px] px-14 py-11 bg-[#F67D30] rounded-3xl" data-aos-delay="200">
            <h4 class="heading-4 uppercase">Ethics</h4>
            <p class="text-white text-[18px] max-md:text-[14px] leading-[25.2px] font-normal mt-6">
              We prioritize ethics in all business activities, ensuring integrity and transparency in our partnerships.
            </p>
          </div>
          <div class="w-1/2 max-sm:w-full md:h-[375px] px-14 py-11 bg-white rounded-3xl" data-aos-delay="300">
            <h4 class="heading-4 !text-black uppercase">Meticulousness</h4>
            <p class="text-[18px] max-md:text-[14px] leading-[25.2px] font-normal mt-6">
              We prioritize precision and excellence, ensuring meticulous attention to detail in every task for maximum
              client satisfaction.
            </p>
          </div>
        </div>
      </div>
    </section>

    <section id="our-services" class="mt-[80px] 2xl:container m-auto">
      <div class="md:bg-[url('/images/our-services-bg-fix.png')] bg-contain bg-no-repeat" data-aos="fade-up">
        <h2 class="heading-1">our services</h2>
        <div class="description max-w-[850px] mx-auto mt-[81px] text-center max-md:text-left max-md:mt-[25px]"
          data-aos="fade-up" data-aos-delay="200">
          Our array of services is meticulously organized into various specialized sections, strategically crafted to
          address the unique needs and objectives of our diverse clientele. Each segment is finely tuned to cater to
          specific requirements, ensuring that our offerings are precisely tailored to meet the varied demands of our
          clients. Through this strategic segmentation, we aim to provide a comprehensive suite of solutions that not
          only address immediate needs but also align with long-term objectives, fostering sustainable growth and
          success for our clients.
        </div>
      </div>
    </section>

    <section id="technology" class="mt-[80px]" data-aos="fade-up">
      <div class="bg-white p-16 max-lg:p-6 rounded-3xl">
        <div class="grid grid-cols-4 max-lg:grid-cols-3 max-sm:grid-cols-1 gap-4">
          <div class="min-h-[328px] p-8 max-lg:hidden" data-aos="fade-up">
            <h4 class="text-[20px] text-black font-bold uppercase">Technology</h4>
          </div>
          <div
            class="min-h-[328px] p-8 bg-[url('/images/technology/card-1.png')] bg-no-repeat bg-cover bg-center rounded-[20px]"
            data-aos="fade-up" data-aos-delay="200">
            <h4 class="text-[20px] text-black font-bold uppercase">
              Software <br />
              Development
            </h4>
          </div>
          <div class="min-h-[328px] p-8 bg-[#F67D30] rounded-[20px]" data-aos="fade-up" data-aos-delay="300">
            <h4 class="text-[20px] text-white text-center font-bold uppercase mb-8">
              managed <br />
              services
            </h4>
            <nuxt-picture loading="lazy" src="/images/technology/img-market-trend.png" width="400px"
              :imgAttrs="{ class:'mx-auto picture max-md:hidden', alt: 'img-market-trend' }" />
            <nuxt-picture loading="lazy" src="/images/technology/img-market-trend-mobie.png"
              :imgAttrs="{ alt: 'img-asset-balance', class: 'w-full' }" class="picture hidden max-md:block" />
          </div>
          <div
            class="min-h-[328px] p-8 bg-[url('/images/technology/card-3.png')] bg-no-repeat bg-cover bg-center rounded-[20px]"
            data-aos="fade-up" data-aos-delay="400">
            <h4 class="text-[20px] text-white font-bold uppercase max-md:text-center">
              Website & mobile app development
            </h4>
          </div>
        </div>
        <div class="grid grid-cols-4 max-lg:grid-cols-3 max-sm:grid-cols-1 sm:gap-4 mt-4">
          <div class="min-h-[328px] p-8 bg-[#F67D30] rounded-[20px]" data-aos="fade-up" data-aos-delay="100">
            <h4 class="text-[20px] text-white text-center font-bold uppercase mb-8">
              Blockchain <br />
              technology
            </h4>
            <nuxt-picture loading="lazy" src="/images/technology/img-asset-balance.png" width="400px"
              :imgAttrs="{ class:'picture max-md:hidden mx-auto', alt: 'img-asset-balance' }" />
            <nuxt-picture loading="lazy" src="/images/technology/img-asset-balance-mobie.png"
              :imgAttrs="{ alt: 'img-asset-balance', class: 'picture w-full hidden max-md:block mx-auto' }" />
          </div>
          <div
            class="min-h-[206px] p-8 flex-1 col-span-2 bg-[url('/images/technology/card-5.png')] bg-no-repeat bg-cover bg-center rounded-[20px] max-sm:mt-4"
            data-aos="fade-up" data-aos-delay="200">
            <div class="h-full flex flex-col justify-between">
              <h4 class="text-[14px] max-md:text-[12px] text-black font-bold uppercase">AI Solutions</h4>
              <h4 class="text-[20px] max-md:text-[16px] text-black font-bold uppercase">
                IoT, AR <br />
                Solutions
              </h4>
            </div>
          </div>
          <div class="min-h-[257px] flex justify-items-end items-center max-lg:hidden" data-aos="fade-up"
            data-aos-delay="300">
            <p class="w-full text-right text-[18px] font-normal leading-7">
              We provide comprehensive <br />
              support in system design <br />
              and implementation to <br />
              elevate business <br />
              operations.
            </p>
          </div>
        </div>
        <div class="grid grid-cols-4 gap-4 items-center mt-4">
          <div class="min-h-[257px] p-8 max-lg:hidden" data-aos="fade-up" data-aos-delay="100">
            <p class="text-[18px] font-normal leading-7">
              We take extensive <br />
              responsibility for the <br />
              management and <br />
              maintainance of your IT <br />
              infrastructure so you can <br />
              focus on the things that <br />
              matter.
            </p>
          </div>
          <div
            class="flex-1 min-h-[257px] p-8 pb-[77px] col-span-3 max-lg:col-span-4 bg-[#3D3D42] md:bg-[url('/images/technology/card-6.png')] max-md:bg-[url('/images/technology/card-6-mobie.png')] bg-no-repeat bg-contain bg-left rounded-[20px] max-md:pb-[220px]"
            data-aos="fade-up" data-aos-delay="200">
            <div class="w-fit ml-[40%] max-sm:ml-0">
              <h4 class="text-[20px] text-white font-bold uppercase">design</h4>
              <p class="max-w-[386px] text-[18px] text-white font-normal mt-[23px] max-sm:hidden">
                Design plays a crucial role in shaping brand identity and user experience. Our design services encompass
                graphic design, web design, and user interface/user experience (UI/UX) design, delivering visually
                compelling solutions that resonate with your target audience.
              </p>
              <p class="max-w-[386px] text-[18px] text-white font-normal mt-[23px] hidden max-sm:block">
                Design plays a crucial role in shaping brand identity and user experience.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <CommonQuestion class="mt-[80px] 2xl:container m-auto max-lg:mt-[80px]" />

    <Slide />

    <section id="our-cliens" class="mt-[80px] 2xl:container m-auto" data-aos="fade-up">
      <div
        class="px-[73px] py-[68px] max-lg:p-6 bg-white rounded-3xl md:bg-[url('/images/our-clients-bg.png')] max-md:bg-[url('/images/our-clients-mobie-bg.png')] bg-right-bottom bg-no-repeat bg-cover max-md:pb-[250px]">
        <div data-aos="fade-up">
          <nuxt-picture loading="lazy" src="/images/our-clients.png" width="100%" :imgAttrs="{ alt: 'our-cliens' }" />
        </div>
        <div class="max-w-[70%] max-md:max-w-full mt-9 grid grid-cols-4 max-sm:grid-cols-2 gap-3">
          <div v-for="(clien, index) in listCliens" :key="index"
            class="min-h-[112px] bg-[#717171] flex justify-center items-center rounded-lg mt-2" data-aos="fade-up"
            :data-aos-delay="100 * index">
            <nuxt-picture loading="lazy" :src="clien.image"
              :imgAttrs="{ class: 'mx-auto picture max-md:w-[70px]', alt: 'our-cliens' }" />
          </div>
        </div>
      </div>
    </section>

    <Excellence class="mt-[80px]" />
  </div>
</template>
