<script>
export default {
  data() {
    return {
      slides: [
        {
          title: "Leaders in Technology",
          content:
            "Our company stands at the forefront of technological advancements. We continuously invest in staying ahead of the curve, ensuring that our solutions leverage the latest and most effective technologies. By choosing us, you align your business with leaders in the industry, ready to navigate the ever-changing landscape of technology."
        },
        {
          title: "Tailored Solutions",
          content:
            "Recognizing that each business is unique, we pride ourselves on providing customized solutions. Our team of experienced tech professionals takes the time to understand your specific business needs, challenges, and goals. This personalized approach ensures that the solutions we deliver are not only effective but also seamlessly integrate into your existing processes."
        },
        {
          title: "Customer-Focused Approach",
          content:
            "Your satisfaction is our priority. We understand that technology is a means to an end, and that end is your business success. Our customer-focused approach involves active listening to your needs, concerns, and aspirations. This ensures that our solutions not only address immediate requirements but also contribute to the long-term growth and prosperity of your business."
        },
        {
          title: "Continuous Support",
          content:
            "Our commitment to your success doesn't end with the delivery of a solution. We provide continuous support to ensure that our technology solutions evolve with your business. Whether it's troubleshooting, updates, or scaling up, our support team is dedicated to being there for you every step of the way."
        }
      ],
      currentSlide: 0
    };
  },
  methods: {
    nextSlide() {
      this.$refs.carousel.next();
    },
    prevSlide() {
      this.$refs.carousel.prev();
    }
  }
};
</script>

<template>
  <section id="why-choose-us" class="mt-[80px] 2xl:container m-auto">
    <div class="md:bg-[url('/images/why-choose-us-bg-fix.png')] bg-cover bg-no-repeat" data-aos="fade-up">
      <h2 class="heading-1">WHY choose US?</h2>
      <div class="flex justify-center items-center mt-[80px] pb-12 max-md:mt-[50px] max-lg:flex-col-reverse gap-4">
        <div class="w-1/2 flex gap-3 justify-center">
          <div
            class="w-[48px] h-[48px] border border-[rgba(255,255,255,0.12)] hover:bg-white cursor-pointer rounded-full flex justify-center items-center"
            @click="prevSlide">
            <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="none"
              stroke="white" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" class="hover:stroke-black">
              <polyline points="12 8 8 12 12 16"></polyline>
              <line x1="16" y1="12" x2="8" y2="12"></line>
            </svg>
          </div>
          <div
            class="w-[48px] h-[48px] border border-[rgba(255,255,255,0.12)] hover:bg-white cursor-pointer rounded-full flex justify-center items-center"
            @click="nextSlide">
            <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="none"
              stroke="white" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" class="hover:stroke-black">
              <polyline points="12 8 16 12 12 16"></polyline>
              <line x1="8" y1="12" x2="16" y2="12"></line>
            </svg>
          </div>
        </div>
        <div class="w-1/2 max-lg:w-full" data-aos="fade-up" data-aos-delay="100">
          <a-carousel ref="carousel" :dots="false" autoplay>
            <div v-for="(slide, index) in slides" :key="index">
              <h4>{{ slide.title }}</h4>
              <p class="description max-w-[620px] text-left max-lg:text-center max-md:text-left mt-3 min-h-[150px]">
                {{ slide.content }}
              </p>
            </div>
          </a-carousel>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>
:deep(.slick-slide) {
  height: 160px;
  line-height: 160px;
  overflow: hidden;
}

:deep(.slick-slide h4) {
  color: #fff;
}

:deep(.slick-slide p) {
  color: #fff;
}
</style>