<script setup>
const activeKey = ref(0);
const questions = [
  {
    q: "What are outsourcing services?",
    a: `Outsourcing services involve hiring proficient professionals to handle operational or administrative tasks within your business. These tasks, though feasible in-house, are delegated to external teams to access top talent without the burden of onboarding and managing them. This cost-effective solution enables focus on core business areas, fostering growth and development.`,
  },
  {
    q: "Why outsourcing?",
    a: "Outsourcing software development saves costs on offices and hardware, allows focus on core objectives, provides global expertise, frees in-house teams for primary tasks, and simplifies management with dedicated leaders, enabling product owners to focus on strategy while staying informed on technology.",
  },
  {
    q: "who uses outsourcing?",
    a: `Outsourcing is utilized by businesses of all sizes across diverse industries. Virtually every industry benefits from outsourcing, as it enables the completion of tasks that don't necessitate direct customer or business interaction, such as accounting and payroll.`,
  },
  {
    q: "Does outsourcing reduce risk?",
    a: "Consideration of time zone, cultural environment, and language is paramount when managing and outsourcing development teams. Your outsourcing provider must comprehend your requirements clearly to prevent project delays. Competency in understanding your needs ensures smooth project completion.",
  },
  {
    q: "key issues to consider before outsourcing a service",
    a: "Consideration of time zone, cultural environment, and language is paramount when managing and outsourcing development teams. Your outsourcing provider must comprehend your requirements clearly to prevent project delays. Competency in understanding your needs ensures smooth project completion.",
  },
];
</script>

<template>
  <section>
    <div class="flex p-14 max-lg:px-4 max-lg:py-6 max-lg:flex-col" data-aos="fade-up">
      <div class="flex-1">
        <h2 class="text-left max-md:text-center max-md:text-[20px]">Frequently asked questions</h2>
      </div>

      <div class="flex-1" data-aos="fade-up" data-aos-delay="100">
        <a-collapse
          v-model:activeKey="activeKey"
          :bordered="false"
          expandIconPosition="right"
          :accordion="false"
          class="ant-collapse-borderless--custom !bg-transparent"
        >
          <template #expandIcon="{ isActive }">
            <svg
              v-if="isActive"
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="cursor-pointer"
            >
              <path d="M21.334 16L10.6673 16" stroke="#ffff" stroke-width="1.6" stroke-linecap="round" />
            </svg>
            <svg v-else xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path
                d="M5 12H12M12 12H19M12 12V5M12 12V19"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </template>
          <a-collapse-panel v-for="(question, index) in questions" :key="index" :header="question.q" class="text-white">
            <div v-html="question.a" class="text-white text-[18px] max-md:text-[14px] font-medium text-justify opacity-70"></div>
          </a-collapse-panel>
        </a-collapse>
      </div>
    </div>
  </section>
</template>
